import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaCheckCircle } from "react-icons/fa"
import "./index.css"

import Sidebar from "../components/sidebar/Sidebar"
import TechTag from "../components/tags/TechTag"

const books = [
    { description: "A Tour of C++", link: "https://a.co/d/3qfsr3S" },
    { description: "Version Control with Git: Powerful Tools and Techniques for Collaborative Software Development", link: "https://a.co/d/7ZwPUTJ" },
    { description: "GitOps Cookbook: Kubernetes Automation in Practice", link: "https://a.co/d/fYgmCpc" },
    { description: "Designing Data-Intensive Applications: The Big Ideas Behind Reliable, Scalable, and Maintainable Systems", link: "https://a.co/d/fP006m6" },
    { description: "Learning CoreDNS: Configuring DNS for Cloud Native Environments", link: "https://a.co/d/8bGzubV" },
    { description: "Kubernetes Best Practices: Blueprints for Building Successful Applications on Kubernetes", link: "https://a.co/d/dFlujPD" },
    { description: "Designing Distributed Systems: Patterns and Paradigms for Scalable, Reliable Services", link: "https://a.co/d/dNHSohf" },
    { description: "OAuth 2 in Action", link: "https://a.co/d/76EBvpm" },
    { description: "Kubernetes Up & Running: Dive into the Future of Infrastructure", link: "https://tanzu.vmware.com/content/ebooks/production-kubernetes" },
    { description: "Programming TypeScript: Making Your JavaScript Applications Scale", link: "https://a.co/d/dpDyqiD" },
    { description: "You Don't Know JS: this & Object Prototypes", link: "https://a.co/d/aYpZZhC" },
    { description: "You Don't Know JS: Scope & Closures", link: "https://a.co/d/cN2wQxE" },
    { description: "You Don't Know JS: Up & Going", link: "https://a.co/d/gOy85FX" },
]

const AboutPage = props => {
  const labels = props.data.site.siteMetadata.labels
  const aboutTags = ["react", "nodejs", "html", "css"]
  const tags = {}
  labels.forEach(label => {
    aboutTags.forEach(tag => {
      if (tag === label.tag) {
        tags[tag] = label.name
      }
    })
  })

  return (
    <Layout>
      <SEO title="About" />
      <div className="post-page-main">
        <div className="sidebar px-4 py-2">
          <Sidebar />
        </div>

        <div className="post-main">
          <SEO title="About" />
          <div className="mt-3">
            <h2 className="heading">About</h2>
            <p>
              Hello there! Welcome to{" "}
              <b>
                <a href="https://developerdiary.me">developerdiary.me</a>
              </b>{" "}
              ! As the name of this website suggests this is more like a diary
              of what I do and learn as a software developer/engineer. I'm
              posting here for myself (to easily find in future) and also
              thinking it is helpful to someone else who shares the same
              interests as me. If you find something useful and want to say
              thank you, feel free to
              <b>
                <a href="https://www.buymeacoffee.com/amilasenadheera">
                  {" "}
                  buy me a coffee
                </a>
              </b>{" "}
              !
            </p>
            <blockquote className="custom-blockquote">
              <p>Everything is vague to a degree you do not realize till you have tried to make it precise.</p>
              <footer>— Bertrand Russell</footer>
            </blockquote>
            <h4>These are {books.length} awesome tech books I've read</h4>
            <p>
              Note: Following is not a <i>list</i> of books, it's a <b>stack</b>{" "}
              of books. What I finish reading recently will appear on top!
            </p>
            <div className="scrollable-container">
              {books.map(({ description, link }) => (
                <div className="">
                  <span className="text-success d-inline-block" title="prism">
                    <FaCheckCircle size={26} style={{ color: "success" }} />
                  </span>
                  <p className="d-inline-block ml-3 w-75 align-top">
                    <a href={link}>{description}</a>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query aboutQuery {
    site {
      siteMetadata {
        labels {
          tag
          tech
          name
          size
          color
        }
      }
    }
  }
`

export default AboutPage
